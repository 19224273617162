import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { formatDate, daysDifference } from '../utils/dateUtils';

const ShipmentModal = ({ show, handleClose, shipmentDetails, newComment, setNewComment, handleAddComment }) => (
  <Modal show={show} onHide={handleClose} size="lg" centered>
    <Modal.Header closeButton>
      <Modal.Title>Shipment Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {shipmentDetails ? (
        <div className="scrollable-list order-details">
          <p><strong>Order No:</strong> {shipmentDetails.order_details?.order_number ?? 'N/A'}</p>
          <p><strong>Amount:</strong> {shipmentDetails.order_details?.order_type ?? 'N/A'}  {shipmentDetails.order_details?.net_payment ?? 'N/A'}</p>
          <p><strong>Tracking No:</strong> {shipmentDetails.awb_no}</p>
          <p><strong>Courier:</strong> <a href={`https://healthfab.ithinklogistics.co.in/postship/tracking/${shipmentDetails.awb_no}`} target="_blank" rel="noreferrer">
            {shipmentDetails.logistic}
          </a></p>
          <p><strong>Current Status:</strong> {shipmentDetails.current_status}</p>
          <p><strong>Last Scan Status:</strong> {shipmentDetails.last_scan_details.remark}</p>
          <p><strong>Days since pickup:</strong> {daysDifference(shipmentDetails)} Days</p>
          <p><strong>Customer Name:</strong> {shipmentDetails.customer_details?.customer_name ?? 'N/A'}</p>
          <p><strong>Contact:</strong> {shipmentDetails.customer_details?.customer_mobile ?? 'N/A'}</p>
          <p><strong>Expected Delivery Date:</strong> {formatDate(shipmentDetails.expected_delivery_date)}</p>
          <p><strong>Address:</strong> {shipmentDetails.customer_details?.customer_address1 ?? 'N/A'} {shipmentDetails.customer_details?.customer_address2 ?? ''} {shipmentDetails.customer_details?.customer_address3 ?? ''} {shipmentDetails.customer_details?.customer_city ?? ''} {shipmentDetails.customer_details?.customer_pincode ?? ''}</p>
        </div>
      ) : (
        <p>No details available</p>
      )}
      <div className="scrollable-list">
        {shipmentDetails?.scan_details?.map((scan, index) => (
          <div key={index}>
            <p><strong>Status:</strong> {scan.status}</p>
            <p><strong>Date & Time:</strong> {formatDate(scan.status_date_time)}</p>
            <p><strong>Location:</strong> {scan.status_location}</p>
            <p><strong>Remark:</strong> {scan.status_remark}</p>
            <hr />
          </div>
        ))}
      </div>
      <div className='comment-list scrollable-list'>
        {shipmentDetails?.comments?.map((comment, index) => (
          <div key={index}>
            <p><strong>{comment.commenter_name}:</strong> {comment.comment_text}</p>
            <p><em>{formatDate(comment.comment_date_time)}</em></p>
          </div>
        ))}
      </div>
      <Form.Group controlId="new-comment">
        <Form.Label>Add a comment</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
      </Form.Group>
      <Button onClick={handleAddComment}>Add Comment</Button>
    </Modal.Body>
  </Modal>
);

export default ShipmentModal;
